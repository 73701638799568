<template>
	<div style="border: 1px solid #ccc;padding:15px;background-color: #f5f5f5;">
		<div style="padding: 15px;background-color: #fff;">
			<div style="display: flex;">
				<label style="margin-right: 10px;line-height:40px;">内容：</label>
				<el-input placeholder="请输入内容" type="textarea" style="width:90%;" :rows="10" v-model="newsdata.content"></el-input>
			</div>
			<div style="display: flex;justify-content: center;">
				<el-button type="primary" style="margin: 15px 0;" @click="updatanews">编辑{{newsdata.type}}内容</el-button>
			</div>
		</div>
	</div>
</template>
<script>
	import Vue from 'vue'
	import {
		Editor,
		Toolbar
	} from '@wangeditor/editor-for-vue'
	export default Vue.extend({
		components: {
			Editor,
			Toolbar
		},
		data() {
			return {
				newsdata: {
					title: '',
					content: '',
					updatetime: '',
					adduser: '',
					imgurl: ''
				},
				editor: null,
				html: '',
				toolbarConfig: {},
				mode: 'default', // or 'simple'
			}
		},
		methods: {
			updatanews() {
				//  编辑新闻
				console.log(this.newsdata);
				if (this.newsdata.content == "") {
					this.$message({
						showClose: true,
						message: '请填写内容',
						type: 'error'
					});
				} else {
					let that = this
					this.$confirm('确定要编辑吗', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then((res1) => {
						console.log(res1);
						if(res1 == 'confirm'){
							console.log('确认');
							that.newsdata.updatatime = that.$gettime()
							that.$post("/index/updatakronodata",that.newsdata).then(res=>{
								if(res.data=='编辑成功'){
									that.$message({
										type: 'success',
										message: '编辑成功!'
									});
								}else{
									that.$message({
										type: 'error',
										message: '编辑失败!'
									});
								}
							})
						}else{
							console.log('取消');
							this.$message({
								type: 'info',
								message: '已取消'
							});
						}
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消'
						});
					});
				}
			}
		},
		mounted() {
			
			this.$get('/index/getkronodata',{
				id:this.$route.params.id
			}).then(res=>{
				this.newsdata = res.data[0]
			})
		},
		beforeDestroy() {
			const editor = this.editor
			if (editor == null) return
			editor.destroy() // 组件销毁时，及时销毁编辑器
		}
	})
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped>
	.avatar-uploader .el-upload {
		border: 1px dashed #a5a5a5;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>